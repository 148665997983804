<template>
  <div>
    <v-card
      dense
      dark
      width="100%"
      class="grey darken-1 pa-0 ma-0"
      tile
      flat
    >
      <v-row no-gutters>
        <v-col cols="12">
          <v-list-item
          >
            <v-list-item-avatar v-if="!isPlaying" @click="playTrack" class="ml-n1 pl-0 mr-0">
              <v-icon size="35">mdi-play-circle-outline</v-icon>
            </v-list-item-avatar>
            <v-list-item-avatar v-if="isPlaying" @click="pauseTrack" class="ml-n1 pl-0 mr-0">
              <v-icon size="35">mdi-pause-circle-outline</v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-btn v-if="showButton" text :to="`track/${track.id}`">
                <v-list-item-title :class="$vuetify.breakpoint.mdAndUp ? 'text-h5' : 'text-sub-title-2'" v-text="track.title"></v-list-item-title>
              </v-btn>
              <v-list-item-title v-else :class="$vuetify.breakpoint.mdAndUp ? 'text-h5' : 'text-sub-title-2'" v-text="track.title"></v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
              <v-progress-circular
                :rotate="360"
                :size="30"
                :width="5"
                :value="downloadProgress"
                :color="!downloading ? 'grey darken-1' : 'progress'"
              >
                <v-btn icon @click="download" :disabled="downloading">
                  <v-icon v-if="downloading" size="20">mdi-progress-download</v-icon>
                  <v-icon v-else size="20">mdi-download-circle-outline</v-icon>
                </v-btn>
              </v-progress-circular>
            </v-list-item-action>
          </v-list-item>
        </v-col>
      </v-row>
      <v-row no-gutters justify="start">
        <v-col cols="12">
          <track-surfer
            ref="track"
            :track="track"
            :play="isPlaying"
            @duration="updateDuration"
            @played="updatePlayed"
            backgroundColor="grey darken-3"
            waveColor="grey"
            :height="$vuetify.breakpoint.mdAndUp ? 120 : 80" />
        </v-col>
      </v-row>
    </v-card>
    <v-footer padless max-width="768" fixed v-if="playerVisible" class="mx-auto">
      <v-card
        outlined
        dense
        width="100%"
      >
        <v-slider
          v-model="progress"
          dense
          @change="setCurrentTime"
          track-color="grey"
          always-dirty
          min="0"
          max="100"
          class="ma-0 mr-2 ml-2 mb-n3 pl-2 pr-2">
          <template v-slot:prepend>
            <span class="mt-1">{{formattedPlayed}}</span>
          </template>
          <template v-slot:append>
            <span class="mt-1">{{formattedTimeLeft}}</span>
          </template>
        </v-slider>
        <v-list
          dense
          class="ma-0 mt-n6"
          >
          <v-list-item dense>
            <v-list-item-content>
              <v-list-item-title>{{ track.title }}</v-list-item-title>
            </v-list-item-content>
            <v-spacer></v-spacer>
            <v-list-item-icon>
              <v-btn icon @click="this.$refs.track.skipBackward">
                <v-icon>mdi-skip-backward</v-icon>
              </v-btn>
            </v-list-item-icon>
            <v-list-item-icon>
              <v-btn icon @click="stopTrack">
              <v-icon>mdi-stop</v-icon>
            </v-btn>
            </v-list-item-icon>
            <v-list-item-icon>
              <v-btn icon>
                <v-icon v-if="!isPlaying" @click="playTrack">mdi-play</v-icon>
                <v-icon v-if="isPlaying" @click="pauseTrack">mdi-pause</v-icon>
              </v-btn>
            </v-list-item-icon>
            <v-list-item-icon
              class="ml-0"
              :class="{ 'mr-3': $vuetify.breakpoint.mdAndUp }"
            >
              <v-btn icon @click="this.$refs.track.skipForward">
                <v-icon>mdi-skip-forward</v-icon>
              </v-btn>
            </v-list-item-icon>
          </v-list-item>
        </v-list>
      </v-card>
    </v-footer>
  </div>
</template>

<script>
import { saveAs } from 'file-saver'

function getFilesize (url, callback) {
  var xhr = new XMLHttpRequest()
  xhr.open('HEAD', url, true) // Notice "HEAD" instead of "GET",
  xhr.onreadystatechange = function () {
    if (this.readyState === this.DONE) {
      callback(parseInt(xhr.getResponseHeader('Content-Length')))
    }
  }
  xhr.send()
}

export default {
  name: 'TrackPlayerCard',
  components: {
    TrackSurfer: () => import('./TrackSurfer.vue')
  },
  data () {
    return {
      duration: 0,
      formattedDuration: '',
      played: 0,
      formattedPlayed: '',
      formattedTimeLeft: '',
      isPlaying: false,
      playerVisible: false,
      progress: 0,
      downloading: false,
      fileSize: 0,
      downloadProgress: 0
    }
  },
  props: ['track', 'showButton'],
  methods: {
    download () {
      const that = this
      this.downloading = true
      const trackGetter = new XMLHttpRequest()
      trackGetter.open('GET', this.track.src, true)
      trackGetter.responseType = 'blob'
      trackGetter.onreadystatechange = function (e) {
        if (trackGetter.readyState === 4 && trackGetter.status === 200) {
          var blob = new Blob([trackGetter.response], { type: 'audio/wav' })
          saveAs(blob, that.track.src)
          that.downloading = false
        }
      }
      trackGetter.onprogress = function (e) {
        const bytesTransferred = e.loaded
        that.downloadProgress = 100 - (that.fileSize - parseInt(bytesTransferred)) / that.fileSize * 100
      }
      trackGetter.onload = function (e) {
        const bytesTransferred = e.loaded
        that.downloadProgress = 100 - (that.fileSize - parseInt(bytesTransferred)) / that.fileSize * 100
      }
      trackGetter.send()
    },
    playTrack () {
      this.playerVisible = true
      this.isPlaying = true
      this.$emit('playing', this.track.id)
    },
    pauseTrack: function () {
      if (this.isPlaying === true) {
        this.isPlaying = false
      }
    },
    stopTrack () {
      this.$refs.track.stop()
      this.isPlaying = false
    },
    pausedByOtherTrack: function () {
      if (this.isPlaying === true) {
        this.isPlaying = false
      }
      this.playerVisible = false
    },
    formatTime (duration) {
      const d = Number(duration)
      const h = Math.floor(d / 3600)
      const m = Math.floor(d % 3600 / 60)
      const s = Math.floor(d % 3600 % 60).toFixed(0)
      const hDisplay = h > 0 ? ('0' + h).slice(-2) + ':' : ''
      const mDisplay = ('0' + m).slice(-2) + ':'
      const sDisplay = ('0' + s).slice(-2)
      if (this.duration > 3600) {
        return hDisplay + mDisplay + sDisplay
      } else if (this.duration > 60) {
        return mDisplay + sDisplay
      } else {
        return sDisplay
      }
    },
    updateDuration: function (duration) {
      this.duration = duration // (duration / 60).toFixed(2)
      this.formattedDuration = this.formatTime(duration)
      this.formattedPlayed = this.formatTime(0)
      this.formattedTimeLeft = this.formatTime(duration)
    },
    updatePlayed: function (played, source) {
      this.played = played
      this.formattedPlayed = this.formatTime(played)
      this.formattedTimeLeft = this.formatTime(this.duration - played)
      this.progress = this.played / this.duration * 100
    },
    setCurrentTime: function () {
      this.$refs.track.setCurrentTime(this.progress * this.duration / 100)
    }
  },
  created () {
    getFilesize(this.track.src, (size) => {
      this.fileSize = size
    })
  }
}
</script>

<style>
.v-btn {
  text-transform:none !important;
}
</style>
